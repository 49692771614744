import React, { useEffect, useState } from "react";
import "components/Testimony/styles.css";

import TestimonyItem from "components/Testimony/partials/TestimonyItem";
import Bullets from "components/Testimony/partials/Bullets";

import testimonies from "components/Testimony/testimonies.json";

const Testimony = (props) => {
    let staticSelectedIndex = 0;

    // animation static
    const animationMilis = 1000;
    const initialTransition = {
        transition: `all ${animationMilis}ms ease`,
        opacity: 1,
        transform: `translate(0px, 0px)`,
    };

    // state
    const [styleTransition, setStyleTransition] = useState(initialTransition);
    const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect
    useEffect(() => {
        clearInterval(slideAnimation);
        setInterval(() => slideAnimation(staticSelectedIndex), 3000); // loop in 3 sec

        return clearInterval(slideAnimation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // animation function
    const slideAnimation = () => {
        const tempNewIndex = staticSelectedIndex + 1;
        const newIndex = tempNewIndex < testimonies.length ? tempNewIndex : 0;
        staticSelectedIndex = newIndex;
        setCurrentIndex(staticSelectedIndex);

        const windowWidth = window.innerWidth;
        const translateDistance = windowWidth;

        const currentTransition = {
            transition: `all ${animationMilis}ms ease`,
            transform: `translate(-${newIndex * translateDistance}px, 0px)`,
        };
        setStyleTransition(currentTransition);
    };

    // render
    return (
        <div className="testimony-container">
            <div className="testimony-container-list" style={styleTransition}>
                {testimonies.map((val) => (
                    <div className="flex-column-align-center" key={val[0].name}>
                        <TestimonyItem data={val[0]} />
                        <br />
                        <TestimonyItem data={val[1]} />
                    </div>
                ))}
            </div>
            <Bullets currentIndex={currentIndex} data={testimonies} />
        </div>
    );
};

export default Testimony;
