export const formatDateDDMMMYYY = (input) => {
    const date = new Date(input);
    if (!isValidDate(date)) return input;
    var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const formatted = date.toLocaleDateString("id", options); // Saturday, September 17, 2016
    return formatted;
};

const isValidDate = (d) => d instanceof Date && !isNaN(d);
