import React from "react";

import "components/Header/partials/lineSeparator/styles.css";

const LineSeparator = () => {
    const openHome = () => {
        const url = `https://bestlightphoto.id/`;
        window.open(url);
    };
    const renderHamburgerIcon = () => (
        <svg
            onClick={openHome}
            viewBox="30 30 450 450"
            className="lineseparator-hamburger-container"
            width="18"
            height="18"
        >
            <path d="M64 384h384v-42.666H64V384zm0-106.666h384v-42.667H64v42.667zM64 128v42.665h384V128H64z"></path>
        </svg>
    );
    return (
        <div className="lineseparator-container">
            <div className="lineseparator-line" />
            {renderHamburgerIcon()}
            <div className="lineseparator-line" />
        </div>
    );
};

export default LineSeparator;
