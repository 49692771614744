import React from "react";
import "components/Testimony/partials/TestimonyItem/styles.css";

const TestimonyItem = ({ data }) => {
    const { name, campus, desc, image } = data;

    // render
    return (
        <div className="testimonyitem-outer-container">
            <div className="testimonyitem-container">
                <img alt="" className="testimonyitem-image" src={image} />
                <div className="testimonyitem-container-text">
                    <div className="testimonyitem-text-name">{name}</div>
                    <div className="testimonyitem-text-stars">{campus}</div>
                    <div className="testimonyitem-container-line-separator">
                        <div className="testimonyitem-line-separator" />
                    </div>
                    <div className="testimonyitem-text-description">{desc}</div>
                </div>
            </div>
        </div>
    );
};

export default TestimonyItem;
