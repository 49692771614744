import React from "react";
import "components/Header/styles.css";

import ImageCarousel from "components/Header/partials/ImageCarousel";
import LineSeparator from "components/Header/partials/lineSeparator";

const Header = (props) => {
    const { widthtWindow } = props;

    const openHome = () => {
        const url = `https://bestlightphoto.id/`;
        window.open(url);
    };

    // render
    return (
        <div className="app-padding-container flex-column-align-center">
            <img
                alt="logo"
                className="header-image-logo"
                src="https://bestlightphoto.id/images/bestlight_logo.png"
                onClick={openHome}
            />
            <LineSeparator />

            <ImageCarousel widthtWindow={widthtWindow} />
        </div>
    );
};

export default Header;
