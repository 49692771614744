import React, { useEffect, useState } from "react";

import Header from "components/Header";
import Form from "components/Form";
import Testimony from "components/Testimony";
import Footer from "components/Footer";

import "App.css";

function App() {
    // state
    const [heightWindow, setHeightWindow] = useState(window.innerHeight);
    const [widthtWindow, setWidthWindow] = useState(window.innerWidth);
    const [platform, setPlatform] = useState("desktop");

    const resize = () => {
        setHeightWindow(window.innerHeight);
        setWidthWindow(window.innerWidth);
        if (window.innerWidth >= 1025) {
            // desktop
            setPlatform("desktop");
        } else if (window.innerWidth >= 768) {
            // tablet
            setPlatform("tablet");
        } else {
            // phone
            setPlatform("phone");
        }
    };

    // useEffect
    useEffect(() => {
        window.addEventListener("resize", resize);
        resize();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="app-container">
            <Header
                heightWindow={heightWindow}
                widthtWindow={widthtWindow}
                platform={platform}
            />
            <Form
                heightWindow={heightWindow}
                widthtWindow={widthtWindow}
                platform={platform}
            />
            <Testimony
                heightWindow={heightWindow}
                widthtWindow={widthtWindow}
                platform={platform}
            />
            <Footer
                heightWindow={heightWindow}
                widthtWindow={widthtWindow}
                platform={platform}
            />
        </div>
    );
}

export default App;
