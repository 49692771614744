import React from "react";

import "components/Testimony/partials/Bullets/styles.css";

const Bullets = (props) => {
    const { currentIndex, data } = props;
    return (
        <div className="bullets-container">
            {data.map((_, index) => (
                <div
                    key={index}
                    className="bullets-bullet"
                    style={{
                        backgroundColor:
                            index === currentIndex ? "#ffb300" : undefined,
                    }}
                />
            ))}
        </div>
    );
};

export default Bullets;
