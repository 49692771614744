import React, { useEffect, useState } from "react";
import "components/Header/partials/ImageCarousel/styles.css";

const ImageCarousel = () => {
    let staticSelectedIndex = 0;

    // image src
    const arrImageSrc = [
        "https://bestlightphoto.id/images/header_1.jpg",
        "https://bestlightphoto.id/images/header_2.jpg",
        "https://bestlightphoto.id/images/header_3.jpg",
        "https://bestlightphoto.id/images/header_4.jpg",
        "https://bestlightphoto.id/images/header_5.jpg",
    ];

    // animation static
    const animationMilis = 1000;
    const initialTransition = {
        transition: `all ${animationMilis}ms ease`,
        opacity: 1,
        transform: `translate(0px, 0px)`,
    };

    // state
    const [styleTransition, setStyleTransition] = useState(initialTransition);

    // useEffect
    useEffect(() => {
        clearInterval(slideAnimation);
        setInterval(() => slideAnimation(staticSelectedIndex), 2700); // loop in 2.7 sec , unique number so loading assets will not concurrent with testimonies

        return clearInterval(slideAnimation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // animation function
    const slideAnimation = () => {
        const tempNewIndex = staticSelectedIndex + 1;
        const newIndex = tempNewIndex < arrImageSrc.length ? tempNewIndex : 0;
        staticSelectedIndex = newIndex;

        const windowWidth = window.innerWidth;
        const translateDistance = windowWidth >= 640 ? 640 : windowWidth;

        const currentTransition = {
            transition: `all ${animationMilis}ms ease`,
            transform: `translate(-${newIndex * translateDistance}px, 0px)`,
        };
        setStyleTransition(currentTransition);
    };

    return (
        <div className="imagecarousel-container">
            <div
                style={styleTransition}
                className="imagecarousel-container-listimage"
            >
                {arrImageSrc.map((val) => (
                    <img
                        alt=""
                        className="imagecarousel-image"
                        src={val}
                        key={val}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageCarousel;
