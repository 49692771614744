import React, { useState } from "react";
import "components/Form/styles.css";

import { formatDateDDMMMYYY } from "helpers/date";

const Form = (props) => {
    const { platform } = props;
    // state
    const [name, setName] = useState("");
    const [serviceLocation, setServiceLocation] = useState("Jabodetabek");
    const [university, setUniversity] = useState("");
    const [photoLocation, setPhotoLocation] = useState("Kampus");
    const [referal, setReferal] = useState("Instagram");
    const [date, setDate] = useState("");

    const [nameErr, setNameErr] = useState(false);
    const [universityErr, setUniversityErr] = useState(false);
    const [dateErr, setDateErr] = useState(false);

    // ui function
    const onChangeName = (event) => {
        const input = event.target.value;
        setName(input);
        setNameErr(!(input.length > 0));
    };
    const onChangeServiceLocation = (event) => {
        const input = event.target.value;
        setServiceLocation(input);
    };
    const onChangeUniversity = (event) => {
        const input = event.target.value;
        setUniversity(input);
        setUniversityErr(!(input.length > 0));
    };
    const onChangePhotoLocation = (event) => {
        const input = event.target.value;
        setPhotoLocation(input);
    };
    const onChangeReferal = (event) => {
        const input = event.target.value;
        setReferal(input);
    };
    const onChangeDate = (event) => {
        const input = event.target.value;
        setDate(input);
        setDateErr(!(input.length > 0));
    };
    const onClickSend = () => {
        if (
            !name ||
            !serviceLocation ||
            !university ||
            !photoLocation ||
            !referal ||
            !date
        ) {
            if (!name) setNameErr(true);
            if (!university) setUniversityErr(true);
            if (!date) setDateErr(true);
            return;
        }

        const phoneNumber = "6282123389449";
        const newline = "%0a";
        const opening = `
		Hallo,
		${newline}
		Aku udah lihat instagramnya bestlight dan tertarik dengan hasil karya fotonya. Boleh saya tanya-tanya dan minta pricelistnya 😊.
		${newline}
		___
		${newline}`;
        const closing = `
		${newline}
		${newline}
		Terima kasih :)`;
        const textWhatsApp = `
			${opening}
			Name : ${name}
			${newline}
			Area service : ${serviceLocation}
			${newline}
			Kampus / Universitas : ${university}
			${newline}
			Lokasi foto : ${photoLocation}
			${newline}
			Dari mana anda mengetahui Bestlightphoto : ${referal}
			${newline}
			Tanggal Perkiraan Photoshoot : ${formatDateDDMMMYYY(date)}
			${closing}
		`;
        const url = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${textWhatsApp}&app_absent=0`;
        window.open(url, "_blank");
    };

    // render
    return (
        <div className="app-padding-container flex-column-align-center">
            <div className="form-text-header">
                Isi form di bawah ini,
                <br />
                untuk mendapatkan <b>full pricelist & penawaran special</b> dari
                Bestlight Photography
            </div>
            <br />
            <div className="form-field-container">
                <p className="form-text-label">Nama</p>
                <input
                    className="form-single"
                    style={{ borderColor: nameErr ? "red" : undefined }}
                    type="text"
                    placeholder="Ayu Larasati..."
                    value={name}
                    onChange={onChangeName}
                />
                {nameErr && (
                    <span className="form-text-error-label">
                        Bagian ini harus diisi
                    </span>
                )}
            </div>

            <div className="form-field-container">
                <p className="form-text-label">Pilih area service</p>
                <select
                    className="form-single form-select-custom-arrow"
                    onChange={onChangeServiceLocation}
                    value={serviceLocation}
                >
                    <option value="Jabodetabek">Jabodetabek</option>
                    <option value="Jogja">Jogja</option>
                    <option value="Medan">Medan</option>
                    <option value="Bandung">Bandung</option>
                    <option value="Lainnya">Lainnya</option>
                </select>
            </div>

            <div className="form-field-container">
                <p className="form-text-label">Asal Kampus / Universitas</p>
                <input
                    className="form-single"
                    style={{ borderColor: universityErr ? "red" : undefined }}
                    type="text"
                    placeholder="Universitas Indonesia..."
                    value={university}
                    onChange={onChangeUniversity}
                />
                {universityErr && (
                    <span className="form-text-error-label">
                        Bagian ini harus diisi
                    </span>
                )}
            </div>

            <div className="form-field-container">
                <p className="form-text-label">Lokasi foto</p>
                <select
                    className="form-single form-select-custom-arrow"
                    onChange={onChangePhotoLocation}
                    value={photoLocation}
                >
                    <option value="Kampus">Kampus</option>
                    <option value="Spot Outdoor selain Kampus">
                        Spot Outdoor selain Kampus
                    </option>
                    <option value="Studio">Studio</option>
                    <option value="Rumah">Rumah</option>
                </select>
            </div>

            <div className="form-field-container">
                <p className="form-text-label">
                    Dari mana anda mengetahui Bestlightphoto
                </p>
                <select
                    className="form-single form-select-custom-arrow"
                    onChange={onChangeReferal}
                    value={referal}
                >
                    <option value="Instagram">Instagram</option>
                    <option value="Google">Google</option>
                    <option value="Teman Kerabat">Teman Kerabat</option>
                    <option value="Youtube">Social Media Lainnya</option>
                </select>
            </div>

            <div className="form-field-container">
                <p className="form-text-label">Tanggal Perkiraan Photoshoot</p>
                <input
                    className={
                        platform === "desktop"
                            ? "form-single"
                            : "form-single form-select-custom-arrow"
                    }
                    style={{
                        borderColor: dateErr ? "red" : undefined,
                        minHeight: platform !== "desktop" ? 36 : undefined,
                        width: platform !== "desktop" ? "100%" : undefined,
                    }}
                    type="date"
                    id="birthday"
                    name="birthday"
                    placeholder="28 Feburari 2022.."
                    value={date}
                    onChange={onChangeDate}
                />
                {dateErr && (
                    <span className="form-text-error-label" style={{ top: 8 }}>
                        Bagian ini harus diisi
                    </span>
                )}
            </div>

            <div className="form-field-container">
                <div className="form-button" onClick={onClickSend}>
                    Get Pricelist!
                </div>
            </div>
        </div>
    );
};

export default Form;
