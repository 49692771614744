import React from "react";
import "components/Footer/styles.css";

import {
    renderIconWhatsapp,
    renderIconEmail,
    renderIconLocation,
    renderIconInstagram,
    renderIconTiktok,
} from "components/Footer/partials/Icon";

const Footer = (props) => {
    const { platform } = props;

    // ui function
    const onClickInstagram = () => {
        const url = "https://www.instagram.com/bestlight.photo/";
        window.open(url, "_blank");
    };
    const onClickWhatsapp = () => {
        const phoneNumber = "6282123389449";
        const url = `https://api.whatsapp.com/send/?phone=${phoneNumber}&app_absent=0`;
        window.open(url, "_blank");
    };
    const onClickEmail = () => {
        const url = "mailto:contact.bestlight@gmail.com";
        window.open(url, "_blank");
    };
    const onClickMaps = () => {
        const url = "https://goo.gl/maps/2EcNNkbsP1PESXnH9";
        window.open(url, "_blank");
    };
    const onClickTiktok = () => {
        const url = "https://www.tiktok.com/@bestlight.photo?";
        window.open(url, "_blank");
    };

    const renderDesktop = () => (
        <div className="footer-container-content">
            <div className="footer-container-content-item">
                <div className="footer-text-title">Social Media</div>
                <div className="flex-row">
                    <div className="touchable" onClick={onClickInstagram}>
                        {renderIconInstagram()}
                    </div>
                    <div className="touchable" onClick={onClickTiktok}>
                        {renderIconTiktok()}
                    </div>
                </div>
            </div>
            <div className="footer-container-content-item">
                <div className="footer-text-title">About Us</div>
                <div>
                    Graduation Photographer based in Bogor Specialist for
                    Graduation and Family or Gathering
                </div>
            </div>
            <div className="footer-container-content-item">
                <div className="footer-text-title">Alamat</div>
                <div
                    className="flex-row footer-margin-bottom-8px touchable"
                    onClick={onClickWhatsapp}
                >
                    {renderIconWhatsapp()}
                    <div>0821-2338-9449</div>
                </div>
                <div
                    className="flex-row footer-margin-bottom-8px touchable"
                    onClick={onClickEmail}
                >
                    {renderIconEmail()}
                    <div>contact.bestlight@gmail.com</div>
                </div>
                <div className="flex-row touchable" onClick={onClickMaps}>
                    {renderIconLocation()}
                    <div>Dramaga Cantik Residence, Blok K18, Bogor</div>
                </div>
            </div>
        </div>
    );

    const renderPhone = () => (
        <div className="footer-container-content-mobile">
            <div className="footer-container-content-mobile">
                <div className="footer-text-title">Social Media</div>
                <div className="flex-row">
                    <div className="touchable" onClick={onClickInstagram}>
                        {renderIconInstagram()}
                    </div>
                    <div className="touchable" onClick={onClickTiktok}>
                        {renderIconTiktok()}
                    </div>
                </div>
            </div>
            <div className="footer-container-content-mobile">
                <div className="footer-text-title">About Us</div>
                <div>
                    Graduation Photographer based in Bogor Specialist for
                    Graduation and Family or Gathering
                </div>
            </div>
            <div className="footer-container-content-mobile">
                <div className="footer-text-title">Alamat</div>
                <div
                    className="flex-row footer-margin-bottom-8px touchable"
                    onClick={onClickWhatsapp}
                >
                    {renderIconWhatsapp()}
                    <div>0821-2338-9449</div>
                </div>
                <div
                    className="flex-row footer-margin-bottom-8px touchable"
                    onClick={onClickEmail}
                >
                    {renderIconEmail()}
                    <div>contact.bestlight@gmail.com</div>
                </div>
                <div className="flex-row touchable" onClick={onClickMaps}>
                    {renderIconLocation()}
                    <div>Dramaga Cantik Residence, Blok K18, Bogor</div>
                </div>
            </div>
        </div>
    );

    const renderButtonSocmed = () => {
        return (
            <div className="flex-column-align-center" style={{ marginTop: 24 }}>
                <div
                    className="footer-button-instagram"
                    onClick={onClickInstagram}
                >
                    Visit Instagram
                </div>
                <div className="footer-button-tiktok" onClick={onClickTiktok}>
                    Visit Tiktok
                </div>
            </div>
        );
    };

    // render
    return (
        <div>
            {renderButtonSocmed()}
            <div className="footer-container">
                <div className="footer-container-content">
                    {platform === "desktop" ? renderDesktop() : renderPhone()}
                </div>
            </div>
        </div>
    );
};

export default Footer;
